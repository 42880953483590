@import '@Styles/variables.module.scss';

.halfWidth {
  display: flex;
  justify-content: space-between;

  > * {
    max-width: 49%;
    flex: 1;

    @media screen and ($screenXs) {
      max-width: unset;
    }
  }

  @media screen and ($screenXs) {
    flex-direction: column;
  }
}

.hidden {
  display: none;
}

.headerWithChip {
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 12px;
  }
}

.chipContent {
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 5px;
  }
}

.membersContactHeader {
  margin-top: 0px;
}
