@import '@Styles/variables.module.scss';

.contentContainer {
  max-width: 1280px;
  width: 75%;
  margin: 0 auto 0 0;
  @media screen and ($screenSm) {
    width: 100%;
  }
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  padding-top: 40px;
  padding-bottom: 80px;
}

.fullWidth {
  width: 100%;
}
