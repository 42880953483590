@import '@Styles/variables.module.scss';

.contentContainer {
  max-width: 800px;
  width: 100%;
  padding: 0 64px;
  margin: 0 auto;

  @media screen and ($screenSm) {
    padding: 0 30px;
  }

  @media screen and ($screenXs) {
    padding: 0;
  }
}

.button {
  border-top: 1px solid $colorT2Paler;
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  padding-top: 40px;
}

.additionalInfoHeader {
  margin-top: 60px;
}
