@import '@Styles/variables.module.scss';

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 12px;
  align-items: flex-start;
}

.distributedContainer {
  width: 200px;
  text-wrap: wrap;
  flex-grow: 1;
}

.distributedPercentageBar {
  margin-top: 2px;
  > div {
    background-image: linear-gradient(300deg, $AFFuchsiaPink500 17.16%, $AFNavyBlue300 80.8%);
  }
}

.fundedProjects {
  border-bottom: 1px solid;
  height: fit-content;
}

.fundingTextContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fundingText {
  margin: 0;
  width: fit-content;
}
