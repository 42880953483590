@import '@Styles/variables.module.scss';

.totalizersContainer {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  @media screen and ($screenSm) {
    flex-direction: column;
  }
}
