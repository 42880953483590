@use "sass:math";
@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 80%;
  padding: 12px;
  width: 12em;
  border: $tileBorder;
  border-radius: $tileBorderRadius;
  background-color: $colorT3Paler;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 15%);
  gap: 12px;

  @media screen and ($screenSm) {
    display: none;
  }
}

.title {
  font-family: $fRoboto;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  > p {
    margin: 0;
  }
}

.errorText {
  color: $colorT4;
  margin-top: 1em;
}

.completionBar {
  width: 100%;
  height: 12px !important;
  border-radius: 6px;
  > div {
    background-image: linear-gradient(300deg, $AFDustyPink400 17.16%, $AFNavyBlue300 80.8%);
    border-radius: 6px;
  }
}

.saveDraftButton {
  padding: 10px !important;
  align-self: flex-start;
  margin-top: 24px !important;
  > span {
    font-size: 14px !important;
  }
}

.sectionInfoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.sectionInfoItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.sectionInfoLabel {
  font-size: 12px;
  color: $AFGraphite400;
  margin: 0;
}

.sectionInfoCheck {
  color: $AFGraphite300;
}

.complete {
  color: $AFSuccess;
}
