@import '@Styles/variables.module.scss';

.form {
  margin-top: 50px;
}

.userNote {
  justify-content: center;
  margin-top: 32px;
  text-align: center;
}

.checkboxContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-left: 10px;
  }
}
