@import '@Styles/variables.module.scss';

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 20px;
  justify-content: space-between;

  @media screen and ($screenXs) {
    flex-wrap: wrap;
  }

  > h1 {
    margin: 0 24px 0 0;
    font-size: 48px;
    line-height: 79px;

    @media screen and ($screenXs) {
      font-size: 34px;
      line-height: 62px;
      margin-bottom: 10px;
    }
  }
}

.updatesBoxHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.updatesBoxTitle {
  display: flex;
  flex-wrap: wrap;
}

.progressBox {
  margin-top: 32px;
  border: $tileBorder;
  border-radius: $tileBorderRadius;
}

.progressTitle {
  @media screen and ($screenXs) {
    margin-bottom: 10px;
  }
}

.progressContent {
  padding: 14px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and ($screenXs) {
    flex-direction: column;
  }
}

.progressAmountsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and ($screenXs) {
    align-items: center;
  }
}

.mainAmount {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;

  &.mainAmountShortlist {
    color: $colorT1;
  }

  &.mainAmountInvited {
    color: $colorT3;
  }

  &.mainAmountDanger {
    color: $colorT4;
  }
}

.amountAcceptedWrapper {
  margin-bottom: 5px;
}

.amountAccepted {
  color: $colorT1;
}

.itemsContainer {
  padding: 0 32px;

  > div {
    margin-top: 20px;
  }

  @media screen and ($screenSm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 0;

    > b {
      align-self: flex-start;
    }
  }
}

.tileWrapper {
  position: relative;
  max-width: 100%;
}

.tileOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $colorT2Light;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10%;
  border-radius: 4px;

  @media screen and ($screenSm) {
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
    padding-right: 0;
  }

  > span {
    color: $colorT5;
    font-weight: 600;
    font-size: 24px;
    border: 2px solid $colorT5;
    border-radius: 12px;
    padding: 10px 20px;
  }
}

.volunteeringMessage {
  border: 1px solid $colorP1;
}

.highlightedInfo {
  background-color: $colorT3Pale;
  border: 1px solid $colorT3;
  border-radius: 4px;
  color: $colorT2;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
  margin-top: -14px;
  padding: 24px;
  justify-content: center;

  div {
    height: 28px;
    width: 28px;
  }

  @media screen and ($screenXs) {
    padding: 24px 18px;
  }
}

.shortlistTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.managementButtons {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
