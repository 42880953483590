@import '@Styles/variables.module.scss';

.editInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @media screen and ($screenXs) {
    margin-bottom: 0;
  }
}

.infoIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  svg {
    width: 100%;
    color: $colorT3;
  }
}

.editInfoNote {
  color: $colorT2Light;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  font-weight: bold;
}

.subHeaderLink {
  color: $AFNavyBlue500;
}
