@import '@Styles/variables.module.scss';

.container {
  display: flex;
  gap: 16px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.previewComponents {
  display: flex;
  gap: 8px;
  height: fit-content;
  flex-grow: 1;
  @media screen and ($screenMd) {
    flex-direction: column;
    width: 100%;
  }
}

.sideBarContainer {
  width: 300px;
  flex-shrink: 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
  @media screen and ($screenMd) {
    display: none;
  }
}
