@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subtitleLink {
  color: $AFGraphite400;
}

.fundContainer {
  max-height: 600px;
  overflow-y: auto;
}

.fundContainer::-webkit-scrollbar {
  width: 0.5em;
  background-color: $AFWhiteKnight500;
}

.fundContainer::-webkit-scrollbar-thumb {
  background-color: $AFSteel300;
  border-radius: 100px;
}

.fundItem {
  width: 100%;
}
