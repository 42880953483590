@import '@Styles/variables.module.scss';

.categoriesError {
  border: 1px solid $colorT4;
}

.divider {
  margin: 16px 0 0 0;
}

.errorMessage {
  text-align: left;
  font-size: 12px;
  color: $colorT4;
}
