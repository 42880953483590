@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ctaContainer {
  margin-top: auto;
}

.projectContainer {
  max-height: 550px;
  overflow-y: auto;
}

.projectContainer::-webkit-scrollbar {
  width: 0.5em;
  background-color: $AFWhiteKnight500;
}

.projectContainer::-webkit-scrollbar-thumb {
  background-color: $AFSteel300;
  border-radius: 100px;
}

.projectItem {
  width: 100%;
}
