@import '@Styles/variables.module.scss';

.wrapper {
  margin-bottom: 4em;
  margin-right: 2em;
  margin-top: 4em;
}

.heading {
  font-size: 20px;
  color: $colorT2Lighter;
}

.text {
  font-size: 16px;
  color: $colorT2Lighter;
}
