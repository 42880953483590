@import '@Styles/variables.module.scss';

.container {
  padding: 8px;
  gap: 4px;
  width: 100%;
}

.insideContainer {
  display: flex;
  justify-content: space-between;
}

.topRowContainer {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-grow: 1;
  > p {
    font-weight: 600;
    margin: 4px 0;
  }
}
.topRowPreview {
  flex-grow: unset;
}

.avatar.avatar {
  height: 40px;
  width: 40px;
}

.bottomRowContainer {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.bottomRowContainerLeft {
  align-items: center;
  display: flex;
  gap: 10px;
}

.bottomRowPreview {
  width: fit-content;
}

.button {
  min-width: fit-content !important;
  padding: 8px 16px !important;
  border-radius: 10px !important;
  > span {
    width: fit-content;
    font-size: 14px;
  }
}

.nameContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.fundName {
  font-weight: 600;
  margin: 0;
  max-width: 350px;
  font-family: $fRoboto;
}

.funderName {
  color: $AFGraphite400;
  font-size: 12px;
  margin: 0;
}

.closed {
  margin: 0;
  color: $AFDanger;
}

.notEligibleContainer {
  display: flex;
  gap: 2px;
}

.notEligibleText {
  font-size: 14px;
  margin: 4px 0;
}

.notEligiblityTextHighlight {
  color: $AFDanger;
}

.notEligiblityLink {
  font-weight: 600;
  cursor: default;
}

.chipLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}
