@import '@Styles/variables.module.scss';

.totalizers {
  grid-area: totalizers;
  width: 100%;
}

.message {
  grid-area: message;
  height: fit-content;
}
.lockedFeatureText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 24px;
  * {
    margin: 0;
  }
}
.lockedFeatureContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  @media screen and ($screenXs) {
    flex-direction: column;
  }
  img {
    height: 150px;
  }
}
.projects {
  grid-area: projects;
  flex-direction: column;
  width: 100%;
  max-height: 800px;
  max-width: 970px;
}
.map {
  grid-area: map;
  border-radius: 15px;
  margin-right: -25%;
  @media screen and ($screenLg) {
    margin-right: 0;
  }
}

.resultsContainer {
  display: grid;
  max-width: 1500px;
  margin: 30px auto;
  gap: 30px;
  grid-template-areas: 'totalizers message' 'projects map';
  grid-template-columns: 2fr 1fr;
  padding: 0 10px;
  @media screen and ($screenSm) {
    grid-template-columns: 1fr;
    grid-template-areas: 'message' 'totalizers' 'projects' 'map';
    margin: 30px;
  }
}
.demoBtn {
  max-width: 200px;
  border-radius: 10px !important;
  background-color: $AFChalkyBlue500 !important;
}

.popupMaxSearchesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  h2 {
    margin: 0;
  }
}
