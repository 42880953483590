@import '@Styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 25px 0;
}

.sectionTitle {
  margin-bottom: 0;
}

.sectionSubtitle {
  color: $AFGraphite400;
}
