@import '@Styles/variables.module.scss';

.container {
  position: relative;
  width: 100%;
}

.imageWrapper {
  position: relative;
}

.image {
  width: 65px;
  height: 45px;
  border-radius: 4px;
  object-fit: cover;
}

.videoWatermark.videoWatermark {
  color: rgba(255, 255, 255, 0.6);
  font-size: 45px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.imagePlaceholder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 65px;
  background-color: $colorT3Light;
  color: $colorT3;
  border-radius: 4px;
}

.statusFlag {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 4px;
  padding: 2px;
  font-size: 10px;
  background-color: $AFWhiteKnight500;
  display: flex;
  gap: 2px;
  align-items: center;
}

.DRAFT {
  color: $ProjectDraftColor;
  fill: $ProjectDraftColor;
}

.PUBLISHED {
  color: $ProjectPublishedColor;
  fill: $ProjectPublishedColor;
}

.FUNDED {
  color: $ProjectFundedColor;
  fill: $ProjectFundedColor;
}

.COMPLETE {
  color: $ProjectCompleteColor;
  fill: $ProjectCompleteColor;
}
