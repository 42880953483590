@import '@Styles/variables.module.scss';

.tile {
  width: 100%;
  padding: 10px;
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 4px;
  @media screen and ($screenSm) {
    flex-direction: column;
  }
}

.complete {
  opacity: 0.5;
}

.textContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and ($screenSm) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.text {
  display: flex;
  flex-direction: column;
}

.title {
  margin: 10px 0 0 0;
}

.button {
  height: fit-content;
  padding: 16px 28px;
  align-self: center;
  text-wrap: nowrap;
  min-width: fit-content !important;
}
.completeIndicator {
  color: $AFSuccess;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 2px;
  @media screen and ($screenSm) {
    align-self: center;
  }
}

.actionIcon {
  max-width: 100px;
  max-height: 100px;
  border-radius: 7.5px;
  background-color: $AFNavyBlue500;
  color: $AFDustyPink500;

  > svg {
    padding: 15px;
  }
}
