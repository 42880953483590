@import '@Styles/variables.module.scss';

.areaControlContainer {
  margin-bottom: 24px !important;
}

.fieldHider {
  display: none;
}

.tooltipRow {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
}

.tooltip {
  cursor: pointer;
  width: fit-content;
  display: flex;
  font-size: 18px;
  height: 18px;
}

.showMinimumGrantAmount {
  align-items: center;
  color: $AFGraphite400;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 2em;
  margin-top: -1em;
}

.showIcon {
  color: $AFNavyBlue400;
  margin-right: 4px;
}
