@import '@Styles/variables.module.scss';

.item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $AFGraphite400;
}
.completed {
  color: $AFSuccess;
}
