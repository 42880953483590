@import '@Styles/variables.module.scss';

.contentWrapper {
  @media screen and ($screenMd) {
    max-width: unset;
    width: 100%;

    > form {
      flex: 1;
    }
  }
}
