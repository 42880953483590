@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: $colorT5;
  width: 600px;
  max-width: 95%;
  max-height: 80vh;
  outline: 0;
  border-radius: 4px;
  padding: 40px 60px 50px;
  line-height: 1.3;

  @media screen and ($screenXs) {
    padding: 20px 20px 10px;
  }
}

.title {
  margin: 0 0 18px;
  text-align: center;
  font-size: 32px;
}

.text {
  margin: 0;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
  margin-bottom: -18px;

  @media screen and ($screenXs) {
    flex-direction: column;
    margin-bottom: 10px;

    & > button:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.button.button {
  @media screen and ($screenXs) {
    width: 100%;
  }
}
