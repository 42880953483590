@import '@Styles/variables.module.scss';

.intro {
  padding: 8px 16px;
  background-color: $AFNavyBlue100;
  border-radius: 12px;
}

.header {
  margin-bottom: 0;
}

.subtitle {
  margin-top: 0;
  color: $AFNavyBlue500;
}

.afLogo {
  margin-top: 32px;
}
