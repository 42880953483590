@import '@Styles/variables.module.scss';

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.infoContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: $AFGraphite400;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  @media screen and ($screenXs) {
    flex-wrap: wrap;
  }
}
