@import '@Styles/variables.module.scss';

.container {
  padding: 0;
  width: 100%;
  max-width: 300px;
}

.banner {
  border-radius: 15px 15px 0 0;
  background-color: $AFNavyBlue400;
  img {
    width: 100%;
    padding-bottom: 2px;
  }
}

.textContainer {
  padding: 20px;
}

.title {
  margin: 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
