@import '@Styles/variables.module.scss';

.container {
  display: flex;
  gap: 16px;
}

.mainContainer {
  flex-grow: 1;
}
.sideBarContainer {
  width: 300px;
  flex-shrink: 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  @media screen and ($screenMd) {
    display: none;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
