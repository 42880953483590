@import '@Styles/variables.module.scss';

.tile {
  padding: 10px;
  gap: 4px;
  width: 100%;
}

.fundingOffered {
  border: 1px solid $AFFuchsiaPink500;
}

.hasAcceptedFunding {
  border: 1px solid $AFSuccess;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  width: 100%;
}

.projectInfoContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.CTA {
  align-self: flex-end;
}

.projectName {
  margin: 4px 0;
  font-size: 18px;
  height: 28px;
  overflow: hidden;
  margin: 0;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.button {
  min-width: fit-content !important;
  padding: 8px 16px !important;
  border-radius: 10px !important;
  > span {
    width: fit-content;
    font-size: 14px;
  }
}
