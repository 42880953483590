@import '@Styles/variables.module.scss';

.container {
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 12px;
}

.fundingOffered {
  color: $AFFuchsiaPink500;
}

.hasAcceptedInvitation {
  color: $AFSuccess;
}
