@import '@Styles/variables.module.scss';

.introText {
  margin-bottom: 1em;
}

.sharingLink {
  margin-bottom: 3em;
  margin-top: 1em;
  max-width: 30em;
}

.fieldWrapper {
  margin-top: 1em;
  text-align: left;
  width: 30em;
  max-width: 100%;
}
