@import '@Styles/variables.module.scss';

.header {
  text-align: center;
}

.title {
  font-size: 32px;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  color: $colorT2Lighter;
  margin: 8px 0 0;
}

.content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
  margin-top: 1em;
  width: 100%;
}

.paginationControls {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  margin-top: 1em;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    &:only-child {
      margin: 0 auto;
    }
  }
}
