@import '@Styles/variables.module.scss';

.fundInformationContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 400px;
  gap: 10px;
  flex-wrap: wrap;
}

.fundInformationItem {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: $AFGraphite400;
  gap: 2px;
}

.fundInformationItemPreview {
  font-size: 12px;
}

.iconText {
  display: flex;
  gap: 2px;
  align-items: center;
  max-width: 100px;
  > svg {
    flex-shrink: 0;
  }
}
