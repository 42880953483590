@import '@Styles/variables.module.scss';

.container {
  background-color: $AFSteel300;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.icon {
  background-color: $AFNavyBlue500;
  color: $AFDustyPink400;
  padding: 10px;
  border-radius: 3px;
  align-self: flex-start;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
}

.title {
  margin: 0;
  color: $AFNavyBlue500;
}

.subtitle {
  margin: 0;
  color: $AFGraphite500;
}

.cta {
  text-wrap: nowrap;
}
