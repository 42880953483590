@import '@Styles/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tableHead {
  font-weight: bold !important;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  gap: 3em;
  margin-top: 3em;
}

.linkButton {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.bottomText {
  padding-top: 2em;
}

.button {
  height: 4em !important;
}
