@import '@Styles/variables.module.scss';
.modal {
  background-color: $AFNavyBlue400;
  background-image: url('/assets/SuccessModal/SuccessModalBackground.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: fixed;

  //  Overriding MUI's stupid mobal background alpha because the hex code above doesn't do it
  > div {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  @media screen and ($screenXs) {
    background-image: url('/assets/SuccessModal/SuccessModalBackgroundMobile.svg');
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.tileContainer {
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
}

.tile {
  max-width: 800px;
  max-height: fit-content;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  @media screen and ($screenXs) {
    padding: 2rem 0;
    width: 95%;
  }
}

.closeBtn {
  color: white !important;
  align-self: flex-end !important;
}

.buttonContainer {
  width: fit-content;
  display: flex;
  gap: 10px;
  padding-top: 4rem;
  @media screen and ($screenXs) {
    padding-top: 2rem;
  }
}

.title {
  color: $AFSuccess;
  font-size: 2.5rem;
  @media screen and ($screenXs) {
    font-size: 2rem;
    margin-bottom: 0;
  }
}

.bodyCopy {
  text-align: center;
  width: 60%;
  @media screen and ($screenXs) {
    width: 95%;
    font-size: 1rem;
  }
}
