@import '@Styles/variables.module.scss';

.highlightedInfo {
  background-color: $colorT3Pale;
  border: 1px solid $colorT3;
  border-radius: 4px;
  color: $colorT2;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 40px;
  padding: 24px 46px 24px 24px;

  @media screen and ($screenXs) {
    padding: 24px 18px;
  }
}

.textBold {
  color: $colorT3;
  font-weight: 600;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;

  > *:first-child {
    flex-grow: 1;
    margin-right: 8px;
  }
  > *:nth-child(2) {
    flex-shrink: 0;
    margin-left: 8px;
    max-width: 160px;
    width: 100%;

    @media screen and ($screenXs) {
      max-width: 130px;
    }
  }
}

.volunteeringContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.checkboxError {
  color: #f44336;
}

.subtitle {
  color: $colorT2Lighter;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
