@import '@Styles/variables.module.scss';

.questionWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 12px 24px;
}

.infoTextContainer {
  color: $AFFinnPurple500;
  background-color: $AFNavyBlue100;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
  > svg {
    flex-shrink: 0;
  }
}
