@import '@Styles/variables.module.scss';

.container {
  text-align: center;
}

.troubleBox {
  background-color: $colorT2Paler;
  text-align: left;
  padding: 28px 32px 32px;

  @media screen and ($screenXs) {
    padding: 16px 24px 24px;
  }
}

.troubleTitle {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 24px;
}

.troubleList {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  padding-inline-start: 16px;
  list-style: none;

  > li::before {
    content: '\2022';
    color: $colorP1;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    width: 14px;
    margin-left: -14px;
  }

  > li:not(:last-child) {
    margin-bottom: 10px;
  }
}
