@import '@Styles/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  @media screen and ($screenXs) {
    align-items: stretch;
  }
}

.header {
  margin: 24px 0 32px;
  text-align: center;
  font-size: 32px;

  @media screen and ($screenXs) {
    font-size: 28px;
  }
}

.text {
  margin: 0 0 32px;
  line-height: 1.3;
}

.buttonsWrapper {
  & > button:not(:last-child) {
    margin-right: 18px;

    @media screen and ($screenXs) {
      margin-right: 0;
      margin-bottom: 18px;
    }
  }
}

.button.button {
  @media screen and ($screenXs) {
    width: 100%;
  }
}
