@import '@Styles/variables.module.scss';

.fundCriteriaContainer {
  display: flex;
  flex-direction: row;
}

.criteriaColumnTile {
  max-width: 25%;
  padding: 0;
  overflow: hidden;
}

.criteriaColumnScrollContainer {
  display: flex;
  flex-direction: column;
  max-height: 1000px;
  overflow-y: scroll;
  padding: 20px;
}

.downloadButtons {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.buttonHolder {
  max-width: 300px;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
}

.map {
  width: 100%;
}

.sideControl {
  max-width: 300px;
  width: 100%;
}

.results {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.rightSideControl {
  width: 100%;

  @media screen and ($screenSm) {
    display: none;
  }
}

.mobileHidden {
  @media screen and ($screenXs) {
    display: none;
  }
}

.totalizers {
  border: 1px solid $AFSteel500 !important;
  border-radius: 15px !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

.totalizersTitle {
  margin-top: 0;
}

.totalizerBottomRow,
.totalizerTopRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media screen and ($screenSm) {
    display: flex;
    flex-direction: column;
  }
}

.totalizerBottomRow {
  padding-right: 3em;
  padding-left: 10em;
}

.totalizerStat {
  display: flex;
  flex-direction: column;
  font-family: $fRoboto;
  padding-right: 2em;
  text-align: center;
  width: 20%;
  white-space: pre-wrap;
  strong {
    font-size: 32px;
  }
}

.totalizerProjectMatchValue {
  @extend .totalizerStat;
}

.links {
  width: 100%;
}

.projects {
  flex-direction: column;
  width: 100%;
  max-height: 800px;
  max-width: 970px;
}

.projectPreviewContainer {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.leftRightIcon {
  font-size: 8em;
}

.leftRightIconContainer {
  width: 60px;
}

.exploreCardContainer {
  width: 630px;
}

.smallIcon {
  color: $AFNavyBlue400;
}

.iconText {
  color: $AFDeepBlack;
  font-size: 0.6em;
  margin-top: 0.2em;
  text-decoration: underline;
}

.iconAndLabel {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.buttonsRowRightGroup {
  display: flex;
  gap: 1em;
}

.underlineButton {
  cursor: pointer;
  padding: 1em;
}

.linkButton {
  color: $AFDeepBlack !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.fundCriteriaHeading {
  margin-bottom: 0;
  margin-top: 0;
}
