@import '@Styles/variables.module.scss';

.tileWrapper {
  display: flex;
  // transition: border-color $trsFirst, background-color $trsFirst;
}

.tileWrapperChecked {
  // background-color: $colorP1Pale;
  // border-color: $colorP1;
}

.groupDescription {
  color: $colorT2Lighter;
  display: block;
  font-size: 12px;
  margin: 0;
  text-align: left;
}

.flex {
  // align-items: flex-start;
  // display: flex;
  // margin: 16px;

  > div {
    display: flex;
  }
}

.label {
  margin-left: 0 !important;

  > div:first-child {
    flex-shrink: 0;
    width: auto;
  }
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
}

.tileTitle {
  color: $colorT2;
  font-weight: 500;
  margin: 0;
}
