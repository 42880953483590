@import '@Styles/variables.module.scss';

.container {
  display: flex;
  gap: 2px;
  align-items: center;
}

.icon {
  width: 10px;
  height: 10px;
}

.smallIcon {
  width: 8px;
  height: 8px;
}

.status {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.smallStatus {
  font-size: 10px;
}

.DRAFT {
  color: $ProjectDraftColor;
  fill: $ProjectDraftColor;
}

.PUBLISHED {
  color: $ProjectPublishedColor;
  fill: $ProjectPublishedColor;
}

.FUNDED {
  color: $ProjectFundedColor;
  fill: $ProjectFundedColor;
}

.COMPLETE {
  color: $ProjectCompleteColor;
  fill: $ProjectCompleteColor;
}

.fundedBy {
  color: $AFGraphite400;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
