@import '@Styles/variables.module.scss';

.multipleNumberQuestionContainer {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  @media screen and ($screenSm) {
    grid-template-columns: auto;
  }
}
