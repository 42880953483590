@import '@Styles/variables.module.scss';

.selectedCounter {
  border: 1px solid $AFSteel500;
  border-radius: 4px;
  height: fit-content;
  margin: 0;
  padding: 16px 12px;
}

.errorMessage {
  text-align: left;
  font-size: 12px;
  color: $colorT4;
}
