@import '@Styles/variables.module.scss';

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 20px;
  justify-content: space-between;

  @media screen and ($screenXs) {
    flex-wrap: wrap;
  }

  > div > h1 {
    margin: 0 24px 0 0;
    font-size: 42px;
    line-height: 79px;

    @media screen and ($screenXs) {
      font-size: 34px;
      line-height: 62px;
      margin-bottom: 10px;
    }
  }
}

.infoBox {
  background-color: $colorT4Light;
  border: 1px solid $colorT4;
  border-radius: 4px;
  margin-bottom: 2rem !important;
  padding: 24px 32px;
  position: relative;

  p {
    &:last-child {
      margin: 0;
    }
  }

  svg {
    color: $colorT4;
    margin-right: 1rem;
  }
}

.itemsContainer {
  padding: 32px;

  > div {
    margin-top: 20px;
  }

  @media screen and ($screenSm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 0;

    > b {
      align-self: flex-start;
    }
  }
}

.fundedByContainer {
  max-width: 300px;
  margin: auto;
}

.managementButtons {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.acceptedFundingContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;

  > div > h4 {
    color: $AFGraphite400;
    margin-top: 0;
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 3rem;
}

.actionsTileTitle {
  margin: 0;
}

.actionsTileSubTitle {
  margin-top: 0;
}

.returnLink {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  color: $AFGraphite400;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h1 {
    margin: 0.2rem 0;
  }
  @media screen and ($screenSm) {
    flex-direction: column;
    margin: 0.8rem 0;
  }
}

.warning {
  color: $AFWarningYellow500;
  background-color: $AFWarningYellow100;
}

.overdueText {
  color: $AFDanger;
}

.actionSubtitle {
  display: flex;
  align-items: center;
  gap: 2px;
}

.actionSubtitleText {
  margin: 0;
}

.warningIcon {
  flex-shrink: 0;
  padding-left: 4px;
}
